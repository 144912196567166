<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:35:08
 * @LastEditTime: 2023-07-06 10:24
 * @Descripttion: 试题库【试题】
-->
<style lang="scss" scoped>
.examination-questions {
  @include innerPage(28px 32px 48px 15px);
  @include pageTitle(0, 50%);
  @include pageFoot;

  .questions {
    &-container {
      width: 100%;
      height: calc(100% - 62px);
      box-sizing: border-box;
      padding: 32px 44px 0;
      margin-top: 16px;
    }

    &-wrapper {
      width: calc(100% + 40px);
      height: calc(100% - 74px);
      padding-bottom: 40px;
      box-sizing: border-box;
      position: relative;

      &.no-data {
        width: 100%;
        background: #fff;
        height: calc(100% - 104px);
        border-radius: 10px;
        margin-bottom: 30px;
      }
    }

    &-list {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-right: 40px;
      overflow: hidden;
      overflow-y: auto;

      .question {
        width: 100%;
        margin-bottom: 30px;
        background: #eef0f8;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 30px 48px 0;
        @include flexBox;
        align-items: baseline;
        flex-wrap: wrap;

        .el-button {
          flex-shrink: 0;
        }

        .collect {
          color: #4a4a4a;
          padding: 0;
          height: 38px;

          .button-container {
            @include flexBox;
          }

          .iconfont {
            font-size: 28px;
            color: #d6d8e1;
          }

          &.checked {
            .iconfont {
              color: #feaf2f;
            }
          }
        }

        &--num,
        &--type {
          flex-shrink: 0;
          line-height: 24px;
          flex-shrink: 0;
        }

        &--num {
          color: #1f1f1f;
          font-size: 16px;
        }

        &--type {
          min-width: 56px;
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 14px 0 4px;
          box-sizing: border-box;
          padding: 0 4px;
        }

        &--data {
          flex-grow: 1;
          max-width: calc(100% - 120px);

          h5 {
            line-height: 24px;
            color: #1f1f1f;
          }

          .imgs-group {
            margin-top: 16px;
            @include flexBox;

            .el-image {
              width: 130px;
              height: 78px;
              border-radius: 6px;
              margin-right: 10px;
            }
          }

          .options {
            margin-top: 14px;

            li {
              line-height: 30px;
              color: #666666;

              .imgs-group {
                margin-top: 0;
                margin-bottom: 16px;
                box-sizing: border-box;
                padding-left: 18px;
              }
            }
          }
        }

        &--foot {
          width: 100%;
          height: 72px;
          border-top: 1px solid #dee0e7;
          margin-top: 24px;
          color: #666;
          @include flexBox;

          .time {
            margin-right: 100px;
            @include flexBox;

            .iconfont {
              font-size: 18px;
              margin-right: 6px;
            }
          }

          .level {
            margin-right: auto;
          }

          .collect {
            margin-right: 50px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .compose {
        padding-bottom: 40px;

        h5 {
          @include flexBox;
          align-items: baseline;

          span.bold {
            display: inline-block;
            flex-grow: 1;
            margin-right: 20px;
          }

          .collect {
            margin-right: 34px;
          }

          .iconfont {
            margin-top: 3px;
          }

          .el-button {
            margin-top: -7px;

            &.is-circle {
              margin-top: -4px;
            }
          }
        }

        .subtopic {
          margin-top: 20px;

          &-item {
            width: 100%;
            box-sizing: border-box;
            padding: 40px 30px 0;
            background: #e8eaf3;
            border-radius: 10px;
            color: #666;
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &--title {
            font-size: 16px;
            line-height: 24px;
          }

          &--options {
            box-sizing: border-box;
            padding: 0 40px;
            margin-top: 16px;

            .option {
              line-height: 30px;

              .imgs-group {
                margin-top: 0;
                margin-bottom: 16px;
                box-sizing: border-box;
                padding-left: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.search-options {
  box-sizing: border-box;
  padding: 38px 0;

  .grade {
    width: 360px;
    margin-bottom: 44px;
  }

  .curriculum {
    height: 530px;
    @include flexBox(space-between);

    .chapter,
    .sections {
      width: calc((100% - 4px) / 2);
      height: 100%;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 10px 6px 14px 30px;

      h5 {
        height: 46px;
        box-sizing: border-box;
        padding-right: 18px;
        margin-bottom: 16px;
        @include flexBox(space-between);
      }

      ::v-deep {
        .el-input {
          width: 240px;

          &__inner {
            background: #fff;
          }

          &__suffix .iconfont {
            color: #6c4ecb;
          }
        }
      }
    }

    .chapter {
      background: rgba($color: #feaf2f, $alpha: 0.05);
    }

    .sections {
      background: rgba($color: #6c4ecb, $alpha: 0.05);
    }

    &-item {
      height: calc(100% - 62px);
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: auto;

      li {
        line-height: 42px;
        margin-bottom: 20px;
        font-size: 16px;
        color: #383838;

        &:last-child {
          margin-bottom: 0;
        }

        &.current,
        &:not(.current):not(.not-data):hover {
          color: #6c4ecb;
          cursor: pointer;
        }

        &.not-data {
          height: 100%;
          flex-direction: column;
          @include flexBox(center);

          img {
            width: 125px;
          }

          p {
            color: #b6b6b6;
            margin-top: 6px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.analysis-container {
  box-sizing: border-box;
  padding: 24px 0;
  min-height: 172px;

  .analysis-inner {
    max-height: 492px;
    overflow: hidden;
    overflow-y: auto;
  }

  .answer {
    font-size: 16px;
    color: #2ac293;
    line-height: 26px;
  }

  .analysis-detail {
    color: #484848;
    line-height: 26px;
    margin-top: 20px;
  }
}

.create-question {
  box-sizing: border-box;
  padding: 28px 40px 40px;

  .create-form {
    &-wrapper {
      height: 500px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-right: 46px;
      overflow: hidden;
      overflow-y: auto;

      ::v-deep {
        .el-upload--text {
          width: auto;
        }
      }

      .img-uploader {
        min-width: 46px;
        height: 46px;
        margin-left: 12px;

        ::v-deep .el-upload--text {
          height: 46px;
        }

        .img-box {
          height: 46px;
          position: relative;

          img {
            height: 100%;
            border-radius: 10px;
          }

          .del {
            width: 24px;
            height: 24px;
            background: #fff;
            position: absolute;
            top: -12px;
            right: -8px;
            font-size: 32px;
            border-radius: 50%;
            color: #f8516d;
            @include flexBox(center);
          }
        }

        .upload-wrapper {
          width: 46px;
          height: 46px;
          background: #2ac293;
          border-radius: 10px;
          color: #fff;
          font-size: 30px;
          @include flexBox(center);
        }
      }

      .form-item {
        width: 100%;
        @include flexBox;
        align-items: flex-start;

        .el-button {
          flex-shrink: 0;
          margin-left: 10px;
        }

        .el-form-item {
          flex-grow: 1;
        }
      }

      .question-data {
        ::v-deep .el-checkbox,
        .el-radio {
          &__inner {
            width: 20px;
            height: 20px;
          }

          &__label {
            padding-left: 8px;
            color: #2b2b2b;
          }
        }
      }

      .compose-group .form-item {
        align-items: center;

        .el-form-item {
          margin-bottom: 0;
        }
      }

      .question-options {
        margin-top: 5px;

        .option {
          width: 100%;
          @include flexBox;

          .el-form-item {
            margin-bottom: 0;
            flex-grow: 1;
          }

          &-list {
            width: 100%;
          }

          &-no {
            font-size: 14px;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .el-input {
            flex-grow: 1;
          }

          .el-button {
            flex-shrink: 0;
            margin-left: 10px;
          }
        }

        .el-checkbox,
        .el-radio {
          height: 46px;
          line-height: 46px;
          margin: 10px 0 4px 24px;

          &-group {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      .subtopic-data {
        box-sizing: border-box;
        padding-left: 82px;
      }
    }
  }
}

.import-dialog {
  box-sizing: border-box;
  padding: 40px 20px 28px;

  .download-formwork {
    text-align: right;
    margin-bottom: 18px;
  }

  .file-list {
    .file-item {
      height: 60px;
      margin-top: 20px;
      @include flexBox(space-between, flex-end);

      .iconfont-color {
        font-size: 52px;
        flex-shrink: 0;
        line-height: 60px;
        flex-shrink: 0;
      }

      .el-button {
        flex-shrink: 0;
        margin-bottom: 6px;
      }

      .name {
        color: #212122;
        flex-grow: 1;
        margin: 0 20px 10px;
      }
    }
  }
}



.questionDoc{
    width: 100%;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 90px;
    position: relative;
    .allquestion{
        width: 49%;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        height:  100%;
        overflow: auto;
        margin-right: 2%;
        box-sizing: border-box;
    }
    
    .checkedquestion{
        width: 49%;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
    }
    .questionDocOperation{
        position:absolute;
        left:0;
        width:100%;
        bottom: 0px;
        height: 70px;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    h3{
        font-size: 18px;
        line-height: 30px;
    }
    .hint{
        color: #666666;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .list{
        width:100%;
        .item{
            border:1px solid #DDDDDD;
            width:100%;
            padding:0 15px 15px;
            border-radius: 10px;
            box-sizing: border-box;
            margin-bottom: 20px;
            .headline{
                border-bottom:1px solid #DDDDDD;
                display: flex;
                align-items: center;
                height: 46px;
                .name{
                    font-size: 16px;
                    .bold{
                        min-width: 56px;
                        background: #6340c8;
                        border-radius: 12px 0px 12px 12px;
                        text-align: center;
                        color: #fff;
                        margin: 0 14px;
                        box-sizing: border-box;
                        padding: 0 9px;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 300;
                        display: inline-block;
                    }
                }
                .delete{
                    margin-left: auto;
                    width: 27px;
                    line-height: 27px;
                    background-color: #FF0000;
                    color: #FFFFFF;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 14px;
                    opacity: 0.5;
                    &:hover{
                        cursor: pointer;
                        opacity:1;
                    }
                }
            }
            .realm{
                display: flex;
                align-items: baseline;
                padding:5px 0;
                margin-top: 20px;
                position: relative;
                margin-bottom: 5px;
                .xing{
                    color:#FF0000;
                    position: absolute;
                    left: -10px;
                    top: 4px;
                }
                .title{
                    color:#cccccc;
                    font-size: 12px;
                    margin-left: 5px;
                }
                .addoptin{
                    margin-left: auto;
                    color:#6340c8;
                    font-size: 14px;
                    i{
                        margin-right: 5px;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .topic{
                width: 100%;
                display: flex;
                align-items: center;
            }
            .tinymce{
                flex: 1;
                min-height: 46px;
                line-height: 30px;
                border-radius: 10px;
                font-size: 14px;
                color: #191919;
                box-sizing: border-box;
            }
            .tinymceval{
                background-color: #F6F7FA;
                flex: 1;
                line-height: 22px;
                border-radius: 5px;
                padding: 5px 20px;
            }
            .answers{
                width:100%;
                li{
                    width:100%;  
                    display: flex;
                    align-items: flex-start;    
                    margin-bottom: 10px;  
                    line-height: 32px;
                    .el-checkbox{
                        padding-right: 15px;
                        margin-top: 5px;
                    }      
                    .el-radio{
                        padding-right: 15px;
                        margin-top: 5px;
                    }      
                    ::v-deep .el-radio__label{
                        padding-left:0;
                    }
                    .title{
                        color:#666666;
                        font-size:16px;
                        margin-right: 5px;
                    }
                    .index{
                        margin-right: 5px;
                    }
                    i{
                        margin-left: 10px;
                        width: 27px;
                        line-height: 27px;
                        background-color: #FF0000;
                        color: #FFFFFF;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 14px;
                        opacity: 0.5;
                        &:hover{
                            cursor: pointer;
                            opacity:1;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
  <section class="examination-questions">
    <template v-if="!showQuestionDoc">
      <div class="page-title">
        <h3>{{ $route.meta.title.split(">")[1] }}</h3>
        <div class="search-box">
          <div class="search-synthesize">
            <el-dropdown
              trigger="click"
              @visible-change="dropdownVisible = !dropdownVisible"
            >
              <div class="el-dropdown-link">
                <span>{{ searchForm.type.syque_typ_name }}</span>
                <i class="iconfont" :class="{ revolve: dropdownVisible }"
                  >&#xe635;</i
                >
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :class="{ current: searchForm.type.syque_typ_id == -1 }"
                  @click.native="
                    chooseType({ syque_typ_id: -1, syque_typ_name: '全部' })
                  "
                  >全部
                </el-dropdown-item>
                <el-dropdown-item
                  :class="{
                    current: searchForm.type.syque_typ_id == item.syque_typ_id,
                  }"
                  v-for="item in questionType"
                  :key="item.syque_typ_id"
                  @click.native="chooseType(item)"
                >
                  {{ item.syque_typ_name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-input
              v-model.trim="searchForm.query_word"
              maxlength="100"
              placeholder="搜索题目名称"
            >
              <span slot="prefix" class="iconfont">&#xe61c;</span>
            </el-input>
            <p class="sifting" @click="showDialog('search')">
              <span>筛选</span>
              <i class="iconfont">&#xe600;</i>
            </p>
            <p class="sifting-condition" v-if="searchForm.chapter">
              <span>筛选内容：{{ searchForm.chapter.name }}</span>
              <span v-if="searchForm.joint">&gt;{{ searchForm.joint.name }}</span>
              <el-button
                class="iconfont mini"
                type="custom_primary"
                circle
                @click="[resetSearchChoose, searchData(true)]"
              >
                &#xe620;
              </el-button>
            </p>
          </div>
          <el-button
            type="custom_primary"
            size="medium"
            @click="searchData(false)"
            >搜索</el-button
          >
        </div>
        <div class="btn-group">
          <template v-if="qbType == 1">
            <el-button
              type="custom_warning"
              size="medium"
              @click="showDialog('question')"
            >
              新建+
            </el-button>
          </template>
          <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
            返回
          </el-button>
		  <template v-if="qbType == 1">
      <el-button type="custom_primary search" size="medium" @click="showCutting=true">图片识别</el-button>
			<el-button type="custom_success" size="medium" @click="showDialog('import')"> 导入  <i class="iconfont">&#xe632;</i> </el-button>
		  </template>
		</div>
      </div>
      <div class="questions-container">
        <div
          class="questions-wrapper"
          :class="{ 'no-data': $isEmpty(listData) }"
          v-loading="dataLoad"
        >
          <ul class="questions-list" v-if="!$isEmpty(listData)">
            <!-- 校本库 -->
            <template v-if="qbType == 1">
              <li
                class="question"
                :class="{ compose: isCompose(que.scque_ismakeup) }"
                v-for="(que, que_index) in listData"
                :key="que.scque_id"
              >
                <span class="question--num pf_bold">
                  {{ queNum(que_index + 1) }}.
                </span>
                <span class="question--type">{{ que.syque_typ_name }}</span>
                <div class="question--data">
                  <!-- 组合题 -->
                  <template v-if="isCompose(que.scque_ismakeup)">
                    <h5>
                      <span class="bold"> <em class="richinline" v-html="que.scque_title"></em></span>
                      <el-button
                        type="custom_primary"
                        size="small"
                        @click="getQuestionDetail(que.scque_id)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        class="iconfont"
                        type="custom_primary"
                        circle
                        @click="delQuestion(que.scque_id)"
                        >&#xe620;
                      </el-button>
                    </h5>
                    <div class="imgs-group" v-if="que.scque_title_image">
                      <el-image
                        fit="cover"
                        :src="formatfile(que.scque_title_image)"
                        :preview-src-list="[formatfile(que.scque_title_image)]"
                      />
                    </div>
                    <ol class="subtopic">
                      <li
                        class="subtopic-item"
                        v-for="(sque, sque_index) in que.children_question"
                        :key="sque.scque_id"
                      >
                        <p class="subtopic--title">
                          （{{ sque_index + 1 }}）【{{ sque.syque_typ_name }}】<span class="richinline" v-html="sque.scque_title"></span>
                        </p>
                        <div class="imgs-group" v-if="sque.scque_title_image">
                          <el-image
                            fit="cover"
                            :src="formatfile(sque.scque_title_image)"
                            :preview-src-list="[
                              formatfile(sque.scque_title_image),
                            ]"
                          />
                        </div>
                        <dl
                          class="subtopic--options"
                          v-if="isChoice(sque.syque_typ_id)"
                        >
                          <dt
                            class="option"
                            v-for="opt in sque.question_item"
                            :key="opt.scque_ite_id"
                          >
                            {{ opt.scque_ite_code }}.
                            <span class="richinline" v-html="opt.scque_ite_title"></span>
                            <div class="imgs-group" v-if="opt.scque_ite_image">
                              <el-image
                                fit="cover"
                                :src="formatfile(opt.scque_ite_image)"
                                :preview-src-list="[
                                  formatfile(opt.scque_ite_image),
                                ]"
                              />
                            </div>
                          </dt>
                        </dl>
                        <div class="question--foot">
                          <p class="time">
                            <i class="iconfont">&#xe61a;</i>
                            <span>创建时间：{{ que.create_time }}</span>
                          </p>
                          <p class="level">
                            难易度：{{
                              (sque.scque_difficulty == 10 && "简单") ||
                              (sque.scque_difficulty == 20 && "普通") ||
                              (sque.scque_difficulty == 30 && "困难")
                            }}
                          </p>
                          <el-button
                            type="custom_primary"
                            size="small"
                            @click="showDialog('analysis', sque)"
                          >
                            解析
                          </el-button>
                        </div>
                      </li>
                    </ol>
                  </template>
                  <!-- 其他题型 -->
                  <template v-if="!isCompose(que.scque_ismakeup)">
                    <h5 class="bold"><span class="richinline" v-html="que.scque_title"></span></h5>
                    <div class="imgs-group" v-if="que.scque_title_image">
                      <el-image
                        fit="cover"
                        :src="formatfile(que.scque_title_image)"
                        :preview-src-list="[formatfile(que.scque_title_image)]"
                      />
                    </div>
                    <ul class="options" v-if="isChoice(que.syque_typ_id)">
                      <li
                        v-for="opt in que.children_question"
                        :key="opt.scque_ite_id"
                      >
                        {{ opt.scque_ite_code }}.
                        <span class="richinline" v-html="opt.scque_ite_title"></span>
                        <div class="imgs-group" v-if="opt.scque_ite_image">
                          <el-image
                            fit="cover"
                            :src="formatfile(opt.scque_ite_image)"
                            :preview-src-list="[formatfile(opt.scque_ite_image)]"
                          />
                        </div>
                      </li>
                    </ul>
                  </template>
                </div>
                <!-- 其他题型 -- 底部 -->
                <div class="question--foot" v-if="!isCompose(que.scque_ismakeup)">
                  <p class="time">
                    <i class="iconfont">&#xe61a;</i>
                    <span>创建时间：{{ que.create_time }}</span>
                  </p>
                  <p class="level">
                    难易度：{{
                      (que.scque_difficulty == 10 && "简单") ||
                      (que.scque_difficulty == 20 && "普通") ||
                      (que.scque_difficulty == 30 && "困难")
                    }}
                  </p>
                  <el-button
                    type="custom_primary"
                    size="small"
                    @click="showDialog('analysis', que)"
                  >
                    解析
                  </el-button>
                  <el-button
                    type="custom_primary"
                    size="small"
                    @click="getQuestionDetail(que.scque_id)"
                  >
                    编辑
                  </el-button>
                  <el-button
                    class="iconfont"
                    type="custom_primary"
                    circle
                    @click="delQuestion(que.scque_id)"
                    >&#xe620;
                  </el-button>
                </div>
              </li>
            </template>
            <!-- 平台库 -->
            <template v-if="qbType == 2">
              <li
                class="question"
                :class="{ compose: isCompose(que.plque_ismakeup) }"
                v-for="(que, que_index) in listData"
                :key="que.plque_id"
              >
                <span class="question--num pf_bold">
                  {{ queNum(que_index + 1) }}.
                </span>
                <span class="question--type">{{ que.syque_typ_name }}</span>
                <div class="question--data">
                  <!-- 组合题 -->
                  <template v-if="isCompose(que.plque_ismakeup)">
                    <h5>
                      <span class="bold"> <em class="richinline" v-html="que.plque_title"></em></span>
                    </h5>
                    <div class="imgs-group" v-if="que.plque_title_image">
                      <el-image
                        fit="cover"
                        :src="formatfile(que.plque_title_image)"
                        :preview-src-list="[formatfile(que.plque_title_image)]"
                      />
                    </div>
                    <ol class="subtopic">
                      <li
                        class="subtopic-item"
                        v-for="(sque, sque_index) in que.children_question"
                        :key="sque.plque_id"
                      >
                        <p class="subtopic--title">
                          （{{ sque_index + 1 }}）【{{ sque.syque_typ_name }}】<em class="richinline" v-html="sque.plque_title"></em>
                        </p>
                        <div class="imgs-group" v-if="sque.plque_title_image">
                          <el-image
                            fit="cover"
                            :src="formatfile(sque.plque_title_image)"
                            :preview-src-list="[
                              formatfile(sque.plque_title_image),
                            ]"
                          />
                        </div>
                        <dl
                          class="subtopic--options"
                          v-if="isChoice(sque.syque_typ_id)"
                        >
                          <dt
                            class="option"
                            v-for="opt in sque.question_item"
                            :key="opt.plque_ite_id"
                          >
                            {{ opt.plque_ite_code }}. <span class="richinline" v-html="opt.plque_ite_title"></span>
                            <div class="imgs-group" v-if="opt.plque_ite_image">
                              <el-image
                                fit="cover"
                                :src="formatfile(opt.plque_ite_image)"
                                :preview-src-list="[
                                  formatfile(opt.plque_ite_image),
                                ]"
                              />
                            </div>
                          </dt>
                        </dl>
                        <div class="question--foot">
                          <p class="time">
                            <i class="iconfont">&#xe61a;</i>
                            <span>创建时间：{{ que.create_time }}</span>
                          </p>
                          <p class="level">
                            难易度：{{
                              (sque.plque_difficulty == 10 && "简单") ||
                              (sque.plque_difficulty == 20 && "普通") ||
                              (sque.plque_difficulty == 30 && "困难")
                            }}
                          </p>
                          <el-button
                            type="custom_primary"
                            size="small"
                            @click="showDialog('analysis', sque)"
                          >
                            解析
                          </el-button>
                        </div>
                      </li>
                    </ol>
                  </template>
                  <!-- 其他题型 -->
                  <template v-if="!isCompose(que.plque_ismakeup)">
                    <h5>
                      <span class="bold"> <em class="richinline" v-html="que.plque_title"></em></span>
                    </h5>
                    <div class="imgs-group" v-if="que.plque_title_image">
                      <el-image
                        fit="cover"
                        :src="formatfile(que.plque_title_image)"
                        :preview-src-list="[formatfile(que.plque_title_image)]"
                      />
                    </div>
                    <ul class="options" v-if="isChoice(que.syque_typ_id)">
                      <li
                        v-for="opt in que.children_question"
                        :key="opt.plque_ite_id"
                      >
                        {{ opt.plque_ite_code }}. <span class="richinline" v-html="opt.plque_ite_title"></span>
                        <div class="imgs-group" v-if="opt.plque_ite_image">
                          <el-image
                            fit="cover"
                            :src="formatfile(opt.plque_ite_image)"
                            :preview-src-list="[formatfile(opt.plque_ite_image)]"
                          />
                        </div>
                      </li>
                    </ul>
                  </template>
                </div>
                <!-- 其他题型 -- 底部 -->
                <div class="question--foot" v-if="!isCompose(que.plque_ismakeup)">
                  <p class="time">
                    <i class="iconfont">&#xe61a;</i>
                    <span>创建时间：{{ que.create_time }}</span>
                  </p>
                  <p class="level">
                    难易度：{{
                      (que.plque_difficulty == 10 && "简单") ||
                      (que.plque_difficulty == 20 && "普通") ||
                      (que.plque_difficulty == 30 && "困难")
                    }}
                  </p>
                  <el-button
                    type="custom_primary"
                    size="small"
                    @click="showDialog('analysis', que)"
                  >
                    解析
                  </el-button>
                </div>
              </li>
            </template>
          </ul>
          <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂无试题数据哦~</p>
          </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
          <customPagination
            :current="listPage.pageIndex"
            :total="listPage.total"
            @pageChange="flippingPage"
          />
        </div>
      </div>
    </template>
    <!-- 导入的试题数据 -->
    <div class="questionDoc" v-if="showQuestionDoc">
      <!-- 所有试题 -->
      <div class="allquestion">
        <h3>批量导入习题</h3>
        <div class="hint">成功识别 <span>{{succNum}}</span> 题</div>
        <div class="list">

          <div class="item" v-for="(item, index) in questionDoc" :key="index">
              <div class="headline">
                  <div class="name">第{{ index + 1 }}题<span class="bold">{{ item.syque_typ_name }}</span></div>
                  <div class="delete"><i class="el-icon-delete" @click="deleteQuestionDocItem(index)"></i></div>
              </div>
              <div class="realm">
                  <span class="xing">*</span>
                  题目
              </div>
              <div class="topic">
                  <try-editorquestions class="tinymce" v-model="item.scque_title"></try-editorquestions>
              </div>
              <template v-if="item.syque_typ_id == 2">
                  <!-- 多选题目 -->
                  <div class="realm">
                      <span class="xing">*</span>
                      选项
                      <span class="title">勾选正确答案，多选题请勾选多个选项</span>
                      <div class="addoptin" @click="addDocQuestion(item, index)">
                          <i class="el-icon-circle-plus-outline"></i>添加选项
                      </div>
                  </div>
                  <ul class="answers">
                      <li v-for="(item2, index2) in item.question_item" :key="index2">
                          <el-checkbox v-model="item2.scque_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                          <div class="index">{{ item2.scque_ite_code }}.</div>
                          <try-editorquestions class="tinymce" v-model="item2.scque_ite_title"></try-editorquestions>
                          <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                      </li>
                  </ul>
              </template>
              <template v-if="item.syque_typ_id == 1 || item.syque_typ_id == 5">
                  <!-- 单选题 -->
                  <div class="realm">
                      <span class="xing">*</span>
                      选项
                      <span class="title">勾选正确答案</span>
                      <div class="addoptin" @click="addDocQuestion(item, index)">
                          <i class="el-icon-circle-plus-outline"></i>添加选项
                      </div>
                  </div>
                  <ul class="answers">
                      <li v-for="(item2, index2) in item.question_item" :key="index2">
                          <el-radio v-model="item.answer" :label="item2.scque_ite_code">
                            <span></span>
                          </el-radio>
                          <div class="index">{{ item2.scque_ite_code }}.</div> 
                          <try-editorquestions class="tinymce" v-model="item2.scque_ite_title"></try-editorquestions>
                          <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                      </li>
                  </ul>
              </template>
              <template v-if="item.syque_typ_id == 3 || item.syque_typ_id == 4 || item.syque_typ_id == 7 || item.syque_typ_id == 8">
                  <!-- 填空题 -->
                  <div class="realm">
                      <span class="xing">*</span>
                      答案
                      <div class="addoptin" @click="addDocQuestion(item, index)">
                          <i class="el-icon-circle-plus-outline"></i>添加答案
                      </div>
                  </div>
                  <ul class="answers">
                      <li v-for="(item2, index2) in item.question_item" :key="index2">
                          <div class="title">第{{ index2 + 1 }}空</div>
                          <try-editorquestions class="tinymce" v-model="item2.scque_ite_title"></try-editorquestions>
                          <i class="el-icon-delete" @click="deleteDocQuestionItem(item, index2)"></i>
                      </li>
                  </ul>
              </template>
              <div class="realm">
                  解析
              </div>
              <div class="topic">
                  <try-editorquestions class="tinymce" v-model="item.scque_analysis"></try-editorquestions>
              </div>
              <div class="realm">
                  困难度
              </div>
              <div class="difficulty">
                  <el-radio v-model="item.scque_difficulty" :label="10">简单</el-radio>
                  <el-radio v-model="item.scque_difficulty" :label="20">普通</el-radio>
                  <el-radio v-model="item.scque_difficulty" :label="30">困难</el-radio>
              </div>
              <div class="realm">
                  绑定章节
              </div>
              <el-cascader clearable v-model="item.sccou_cha_id" class="select" :options="chaptersTree" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
          </div>
        </div>
      </div>
      <!-- 选中的试题 -->
      <div class="checkedquestion">
        <div class="list">
          <div class="item" v-for="(item,index) in questionDoc" :key="index">
              <div class="headline">
                  <div class="name">第{{index+1}}题<span class="bold">{{item.syque_typ_name}}</span></div>
              </div>
              <div class="realm">
                  题目
              </div>
              <div class="topic">
                  <div class="tinymceval" v-html="item.scque_title"></div>
              </div>
              <template v-if="item.syque_typ_id==2">
                  <!-- 多选题目 -->
                  <div class="realm">
                      选项
                  </div>
                  <ul class="answers">
                      <li v-for="(item2,index2) in item.question_item" :key="index2">
                          <el-checkbox v-model="item2.scque_ite_isanswer"  :true-label="10" :false-label="20"></el-checkbox>
                          <div class="index">{{item2.scque_ite_code}}.</div>
                          <div class="tinymceval" v-html="item2.scque_ite_title"></div>
                          <i class="el-icon-delete"></i>
                      </li>
                  </ul>
              </template>
              <template v-if="item.syque_typ_id==1||item.syque_typ_id==5">
                  <!-- 单选题 -->
                  <div class="realm">
                      选项
                  </div>
                  <ul class="answers">
                      <li v-for="(item2,index2) in item.question_item" :key="index2">
                          <el-radio v-model="item.answer" :label="item2.scque_ite_code">
                          <span></span>
                          </el-radio>
                          <div class="index">{{item2.scque_ite_code}}.</div> 
                          <div class="tinymceval" v-html="item2.scque_ite_title"></div>
                      </li>
                  </ul>
              </template>
              <template v-if="item.syque_typ_id==3||item.syque_typ_id==4||item.syque_typ_id==7||item.syque_typ_id==8">
                  <!-- 填空题 -->
                  <div class="realm">
                      答案
                  </div>
                  <ul class="answers">
                      <li v-for="(item2,index2) in item.question_item" :key="index2">
                          <div class="title">第{{index2+1}}空</div>
                          <div class="tinymceval" v-html="item2.scque_ite_title"></div>
                      </li>
                  </ul>
              </template>
              <div class="realm">
                  解析
              </div>
              <div class="topic">
                  <div class="tinymceval" v-html="item.scque_analysis"></div>
              </div>
              <div class="realm">
                  困难度
              </div>
              <div class="difficulty">
                  <el-radio v-model="item.scque_difficulty" v-if="item.scque_difficulty==10" :label="10">简单</el-radio>
                  <el-radio v-model="item.scque_difficulty" v-if="item.scque_difficulty==20" :label="20">普通</el-radio>
                  <el-radio v-model="item.scque_difficulty" v-if="item.scque_difficulty==30" :label="30">困难</el-radio>
              </div>
              <div class="realm">
                  绑定章节
              </div>
              <el-cascader v-model="item.sccou_cha_id" class="select"  disabled :options="chaptersTree" :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
          </div>
        </div>
      </div>
      <div class="questionDocOperation">
          <el-button type="custom_primary" size="small" v-loading="submitQuestionDocload" @click="questionDocsubmit()">确 定</el-button>
          <el-button type="custom_warning" size="small" @click="showQuestionDoc=false">取 消</el-button>
      </div>
    </div>
    <!-- 数据筛选弹窗 -->
    <el-dialog
      title="筛选内容"
      :visible.sync="searchDialog"
      width="730px"
      @close="hideDialog('search')"
    >
      <div class="search-options">
        <div class="curriculum">
          <div class="chapter">
            <h5>
              <span class="bold">章</span>
              <el-input
                v-model.trim="chapterKey"
                maxlength="100"
                placeholder="请输入关键字搜索"
                clearable
                @change="getChapters(1)"
                @clear="getChapters(1)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="chapterData && chapterData.length">
                <li
                  :class="{
                    current:
                      chooseData.chapter &&
                      chooseData.chapter.id == item.sccou_cha_id,
                  }"
                  v-for="item in chapterData"
                  :key="item.sysub_id"
                  @click="
                    chooseSearchKey(
                      'chapter',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt="" />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
          <div class="sections">
            <h5>
              <span class="bold">节</span>
              <el-input
                v-model.trim="jointKey"
                maxlength="100"
                placeholder="请输入关键字搜索"
                clearable
                @change="getChapters(2)"
                @clear="getChapters(2)"
              >
                <span slot="suffix" class="iconfont">&#xe61c;</span>
              </el-input>
            </h5>
            <ul class="curriculum-item">
              <template v-if="jointData && jointData.length">
                <li
                  :class="{
                    current:
                      chooseData.joint &&
                      chooseData.joint.id == item.sccou_cha_id,
                  }"
                  v-for="item in jointData"
                  :key="item.sysub_id"
                  @click="
                    chooseSearchKey(
                      'joint',
                      item.sccou_cha_id,
                      item.sccou_cha_title
                    )
                  "
                >
                  {{ item.sccou_cha_title }}
                </li>
              </template>
              <li class="not-data" v-else>
                <img src="@assets/images/no-data2.png" alt="" />
                <p>暂无选项哦~</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_primary" size="small" @click="searchData(true)"
          >确 定</el-button
        >
        <el-button type="custom_warning" size="small" @click="resetSearchChoose"
          >重 置</el-button
        >
      </div>
    </el-dialog>
    <!-- 解析说明 -->
    <el-dialog
      title="解析说明"
      :visible.sync="analysisDialog"
      width="638px"
      :show-close="false"
    >
      <div class="analysis-container">
        <div class="analysis-inner">
          <p class="answer" v-if="analysisData.sure_answer">
            正确答案：<span class="richinline" v-html="analysisData.sure_answer.join(',')"></span>
          </p>
          <p class="analysis-detail" v-if="analysisData.scque_analysis">
            解析说明：<span class="richinline" v-html="analysisData.scque_analysis"></span>
          </p>
          <p class="analysis-detail" v-if="analysisData.plque_analysis">
            解析说明：<span class="richinline" v-html="analysisData.plque_analysis"></span>
          </p>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_primary"
          size="small"
          @click="hideDialog('analysis')"
          >我知道了</el-button
        >
      </div>
    </el-dialog>
    <!-- 新建 / 编辑 试题 -->
    <el-dialog
      :title="(questionForm.scque_id && '编辑试题') || '新建试题'"
      :visible.sync="questionDialog"
      width="738px"
      :show-close="false"
      @close="hideDialog('question')"
    >
      <div class="create-question">
        <div class="create-form-wrapper">
          <el-form
            ref="questionForm"
            :model="questionForm"
            label-width="82px"
            :rules="questionRules"
          >
            <el-form-item label="题干">
              <div class="form-item">
                <el-form-item prop="teque_title">
                    <try-editorquestions class="tinymce" v-model="questionForm.scque_title"></try-editorquestions>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="是否组合题">
              <el-radio-group
                v-model="questionForm.scque_ismakeup"
                @input="checkIsCompose"
              >
                <el-radio :label="20">否</el-radio>
                <el-radio :label="10">是</el-radio>
              </el-radio-group>
              <!-- 组合题添加小题 -->
              <el-button
                v-if="questionForm.scque_ismakeup == 10"
                type="custom_primary"
                size="medium"
                @click="appendQues"
              >
                + 继续加题
              </el-button>
            </el-form-item>
            <div :class="{'compose-group': questionForm.scque_ismakeup == 10,'question-data': questionForm.scque_ismakeup == 20}" v-for="(que, que_index) in questionForm.option_arr" :key="que_index">
              <!-- 组合题 -->
              <template v-if="questionForm.scque_ismakeup == 10">
                <el-form-item label="小题干">
                  <div class="form-item">
                    <el-form-item
                      :prop="
                        'option_arr.' + que_index + '.scque_children_title'
                      "
                      :rules="{
                        trigger: 'blur',
                        validator: function (rule, value, callback) {
                          if (
                            !que.scque_children_title &&
                            !que.scque_children_image
                          ) {
                            callback('请填写题干或者上传题干图片');
                          } else {
                            callback();
                          }
                        },
                      }"
                    >
                      <try-editorquestions class="tinymce" v-model="que.scque_children_title"></try-editorquestions> 
                    </el-form-item>
                    <!-- 删除选项 -->
                    <el-button
                      class="iconfont mini"
                      type="custom_primary"
                      circle
                      @click="removeQues(que_index)"
                      v-if="
                        questionForm.option_arr &&
                        questionForm.option_arr.length > 1
                      "
                    >
                      &#xe620;
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 小题数据 -->
                <div class="subtopic-data">
                  <!-- 题目类型 -->
                  <el-form-item
                    label="选择类型"
                    :prop="`option_arr.${que_index}.syque_typ_id`"
                    :rules="{
                      required: true,
                      message: '请选择题目类型',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      v-model="que.syque_typ_id"
                      placeholder="请选择"
                      @change="chooseQuesType($event, que_index)"
                    >
                      <template v-for="item in questionType">
                        <el-option
                          :key="item.syque_typ_id"
                          :label="item.syque_typ_name"
                          :value="item.syque_typ_id"
                          v-if="item.syque_typ_id != 14"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                  <!-- 选择题选项 -->
                  <el-form-item
                    label="选项"
                    v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                  >
                    <div class="question-options">
                      <!-- 多选选项 -->
                      <el-checkbox-group
                        v-model="que.answer"
                        v-if="que.syque_typ_id == 2"
                      >
                        <ul class="option-list">
                          <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                                :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.scque_ite_title &&
                                      !opt.scque_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editorquestions class="tinymce" v-model="opt.scque_ite_title"></try-editorquestions> 
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `questionForm.option_arr.${que_index}.question_item.${opt_index}.scque_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.scque_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.scque_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.scque_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.scque_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-checkbox :label="opt_index"
                              >设为正确答案</el-checkbox
                            >
                          </li>
                        </ul>
                      </el-checkbox-group>
                      <!-- 单选选项 -->
                      <el-radio-group v-model="que.answer" v-else>
                        <ul class="option-list">
                          <li
                            v-for="(opt, opt_index) in que.question_item"
                            :key="opt_index"
                          >
                            <div class="option">
                              <span class="option-no">
                                {{ opt_index | changeEnChart }}
                              </span>
                              <el-form-item
                                :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                                :rules="{
                                  required: false,
                                  trigger: 'blur',
                                  validator: function (rule, value, callback) {
                                    if (
                                      !opt.scque_ite_title &&
                                      !opt.scque_ite_image
                                    ) {
                                      callback('请填写选项内容或者选项图片');
                                    } else {
                                      callback();
                                    }
                                  },
                                }"
                              >
                                <try-editorquestions class="tinymce" v-model="opt.scque_ite_title"></try-editorquestions>
                              </el-form-item>
                              <!-- <el-upload
                                class="img-uploader"
                                :action="$upload.imgAction"
                                :headers="$upload.header"
                                :show-file-list="false"
                                :accept="$upload.imgAccept"
                                :before-upload="$beforImgUpload"
                                :on-success="
                                  (res) => {
                                    return uploadImgSuccess(
                                      res,
                                      `questionForm.option_arr.${que_index}.question_item.${opt_index}.scque_ite_image`
                                    );
                                  }
                                "
                              >
                                <div
                                  class="img-box"
                                  v-if="opt.scque_ite_image"
                                  @click.stop
                                >
                                  <el-image
                                    style="width: 46px; height: 46px"
                                    :src="formatfile(opt.scque_ite_image)"
                                    :preview-src-list="[
                                      formatfile(opt.scque_ite_image),
                                    ]"
                                  />
                                  <i
                                    class="del iconfont"
                                    @click.stop="opt.scque_ite_image = ''"
                                    >&#xe63e;</i
                                  >
                                </div>
                                <div class="upload-wrapper" v-else>
                                  <i class="iconfont">&#xe63d;</i>
                                </div>
                              </el-upload> -->
                              <!-- 删除选项 -->
                              <el-button
                                class="iconfont mini"
                                type="custom_primary"
                                circle
                                @click="removeQueOption(que_index, opt_index)"
                                v-if="
                                  que.question_item &&
                                  que.question_item.length > 2
                                "
                              >
                                &#xe620;
                              </el-button>
                            </div>
                            <el-radio :label="opt_index">设为正确答案</el-radio>
                          </li>
                        </ul>
                      </el-radio-group>
                      <el-button
                        type="custom_primary"
                        size="medium"
                        @click="appendQueOption(que_index)"
                      >
                        + 继续添加项
                      </el-button>
                    </div>
                  </el-form-item>
                  <!-- 非选择题答案 -->
                  <el-form-item
                    v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                    label="答案"
                    v-for="(opt, opt_index) in que.question_item"
                    :key="opt_index"
                    :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                    :rules="{
                      required: false,
                      trigger: 'blur',
                      validator: function (rule, value, callback) {
                        if (!opt.scque_ite_title && !opt.scque_ite_image) {
                          callback('请填写题目答案');
                        } else {
                          callback();
                        }
                      },
                    }"
                  >
                    <try-editorquestions class="tinymce" placeholder="填空题答案使用英文 , 隔开" v-model="opt.scque_ite_title"></try-editorquestions>
                  </el-form-item>
                  <!-- 难易度 -->
                  <el-form-item
                    label="难易度"
                    :prop="`option_arr.${que_index}.scque_difficulty`"
                    :rules="{
                      required: true,
                      message: '请选择题目难易程度',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      v-model="que.scque_difficulty"
                      placeholder="请选择"
                    >
                      <el-option label="简单" :value="10" />
                      <el-option label="普通" :value="20" />
                      <el-option label="困难" :value="30" />
                    </el-select>
                  </el-form-item>
                  <!-- 解析 -->
                  <el-form-item label="解析说明">
                    <try-editorquestions class="tinymce" v-model="que.scque_analysis"></try-editorquestions> 
                  </el-form-item>
                  <!-- 章节 -->
                  <el-form-item label="绑定章节">
                    <el-cascader
                      v-model="que.chapter"
                      :options="chaptersTree"
                      :props="{
                        value: 'sccou_cha_id',
                        label: 'sccou_cha_title',
                      }"
                      placeholder="请选择"
                    />
                  </el-form-item>
                </div>
              </template>
              <!-- 非组合题 -->
              <template v-if="questionForm.scque_ismakeup == 20">
                <!-- 题目类型 -->
                <el-form-item
                  label="选择类型"
                  :prop="`option_arr.${que_index}.syque_typ_id`"
                  :rules="{
                    required: true,
                    message: '请选择题目类型',
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="que.syque_typ_id"
                    placeholder="请选择"
                    @change="chooseQuesType($event, que_index)"
                  >
                    <template v-for="item in questionType">
                      <el-option
                        :key="item.syque_typ_id"
                        :label="item.syque_typ_name"
                        :value="item.syque_typ_id"
                        v-if="item.syque_typ_id != 14"
                      />
                    </template>
                  </el-select>
                </el-form-item>
                <!-- 选择题选项 -->
                <el-form-item
                  label="选项"
                  v-show="isChoice(que.syque_typ_id) && que.syque_typ_id"
                >
                  <div class="question-options">
                    <!-- 多选选项 -->
                    <el-checkbox-group
                      v-model="que.answer"
                      v-if="que.syque_typ_id == 2"
                    >
                      <ul class="option-list">
                        <li
                          v-for="(opt, opt_index) in que.question_item"
                          :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                              :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                              :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.scque_ite_title &&
                                    !opt.scque_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  } else {
                                    callback();
                                  }
                                },
                              }"
                            >
                              <try-editorquestions class="tinymce" v-model="opt.scque_ite_title"></try-editorquestions>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `questionForm.option_arr.${que_index}.question_item.${opt_index}.scque_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.scque_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.scque_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.scque_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.scque_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                              class="iconfont mini"
                              type="custom_primary"
                              circle
                              @click="removeQueOption(que_index, opt_index)"
                              v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-checkbox :label="opt_index"
                            >设为正确答案</el-checkbox
                          >
                        </li>
                      </ul>
                    </el-checkbox-group>
                    <!-- 单选选项 -->
                    <el-radio-group v-model="que.answer" v-else>
                      <ul class="option-list">
                        <li
                          v-for="(opt, opt_index) in que.question_item"
                          :key="opt_index"
                        >
                          <div class="option">
                            <span class="option-no">
                              {{ opt_index | changeEnChart }}
                            </span>
                            <el-form-item
                              :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                              :rules="{
                                required: false,
                                trigger: 'blur',
                                validator: function (rule, value, callback) {
                                  if (
                                    !opt.scque_ite_title &&
                                    !opt.scque_ite_image
                                  ) {
                                    callback('请填写选项内容或者选项图片');
                                  } else {
                                    callback();
                                  }
                                },
                              }"
                            >
                              <try-editorquestions class="tinymce" v-model="opt.scque_ite_title"></try-editorquestions>
                            </el-form-item>
                            <!-- <el-upload
                              class="img-uploader"
                              :action="$upload.imgAction"
                              :headers="$upload.header"
                              :show-file-list="false"
                              :accept="$upload.imgAccept"
                              :before-upload="$beforImgUpload"
                              :on-success="
                                (res) => {
                                  return uploadImgSuccess(
                                    res,
                                    `questionForm.option_arr.${que_index}.question_item.${opt_index}.scque_ite_image`
                                  );
                                }
                              "
                            >
                              <div
                                class="img-box"
                                v-if="opt.scque_ite_image"
                                @click.stop
                              >
                                <el-image
                                  style="width: 46px; height: 46px"
                                  :src="formatfile(opt.scque_ite_image)"
                                  :preview-src-list="[
                                    formatfile(opt.scque_ite_image),
                                  ]"
                                />
                                <i
                                  class="del iconfont"
                                  @click.stop="opt.scque_ite_image = ''"
                                  >&#xe63e;</i
                                >
                              </div>
                              <div class="upload-wrapper" v-else>
                                <i class="iconfont">&#xe63d;</i>
                              </div>
                            </el-upload> -->
                            <!-- 删除选项 -->
                            <el-button
                              class="iconfont mini"
                              type="custom_primary"
                              circle
                              @click="removeQueOption(que_index, opt_index)"
                              v-if="
                                que.question_item &&
                                que.question_item.length > 2
                              "
                            >
                              &#xe620;
                            </el-button>
                          </div>
                          <el-radio :label="opt_index">设为正确答案</el-radio>
                        </li>
                      </ul>
                    </el-radio-group>
                    <el-button
                      type="custom_primary"
                      size="medium"
                      @click="appendQueOption(que_index)"
                    >
                      + 继续添加项
                    </el-button>
                  </div>
                </el-form-item>
                <!-- 非选择题答案 -->
                <el-form-item
                  v-show="!isChoice(que.syque_typ_id) && que.syque_typ_id"
                  label="答案"
                  v-for="(opt, opt_index) in que.question_item"
                  :key="opt_index"
                  :prop="`option_arr.${que_index}.question_item.${opt_index}.scque_ite_title`"
                  :rules="{
                    required: false,
                    trigger: 'blur',
                    validator: function (rule, value, callback) {
                      if (!opt.scque_ite_title && !opt.scque_ite_image) {
                        callback('请填写题目答案');
                      } else {
                        callback();
                      }
                    },
                  }"
                >
                  <try-editorquestions class="tinymce" placeholder="填空题答案使用英文 , 隔开" v-model="opt.scque_ite_title"></try-editorquestions>
                </el-form-item>
                <!-- 难易度 -->
                <el-form-item
                  label="难易度"
                  :prop="`option_arr.${que_index}.scque_difficulty`"
                  :rules="{
                    required: true,
                    message: '请选择题目难易程度',
                    trigger: 'change',
                  }"
                >
                  <el-select
                    v-model="que.scque_difficulty"
                    placeholder="请选择"
                  >
                    <el-option label="简单" :value="10" />
                    <el-option label="普通" :value="20" />
                    <el-option label="困难" :value="30" />
                  </el-select>
                </el-form-item>
                <!-- 解析 -->
                <el-form-item label="解析说明">
                  <try-editorquestions class="tinymce" v-model="que.scque_analysis"></try-editorquestions> 
                </el-form-item>
                <!-- 章节 -->
                <el-form-item label="绑定章节">
                  <el-cascader
                    v-model="que.chapter"
                    :options="chaptersTree"
                    :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }"
                    placeholder="请选择"
                  />
                </el-form-item>
              </template>
            </div>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('question')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addQuestion"
          :disabled="saveLoad"
          v-loading="saveLoad"
          >确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 操作弹窗 -->
    <el-dialog
      title="导入"
      :visible.sync="importDialog"
      width="560px"
      :show-close="false"
      @close="hideDialog('import')"
    >
      <div class="import-dialog">
        <p class="download-formwork" style="float: left;">
            <el-button type="custom_primary" size="small" @click="downloadTemplate(1)" :disabled="download1"
                v-loading="download1">下载excel模板</el-button>
        </p>
        <p class="download-formwork" style="float: left;margin-left: 20px;">
            <el-button type="custom_primary" size="small" @click="downloadTemplate(2)" :disabled="download2"
                v-loading="download2">下载word模板</el-button>
        </p>
        <el-upload
          :action="$upload.fileAction2"
          :headers="$upload.header"
          drag 
          :show-file-list="fileList.length==0" 
          :file-list="fileList" 
          accept=".doc,.docx,.xls,.xlsx,.xlsm"
          :before-upload="
            (file) => {
              return $beforFileUpload(file, ['doc', 'docx', 'xls', 'xlsx', 'xlsm']);
            }
          "
          :on-success="uploadSuccess"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
        </el-upload>
        <ul class="file-list">
          <li class="file-item" v-for="(item, index) in fileList" :key="index">
            <span
              class="iconfont-color"
              v-html="fileType(item.name.split('.')[1])"
            />
            <p class="name line-text--1st">{{ item.name }}</p>
            <el-button
              class="iconfont mini"
              type="custom_primary"
              circle
              @click="deleteUploadFile(index)"
            >
              &#xe620;
            </el-button>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('import')"
        >
          取 消
        </el-button>
        <el-button
          type="custom_primary"
          size="small"
          @click="uploadQues"
          :disabled="btnload"
          v-loading="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 图片识别 试卷切题 -->
    <cuttingProblem style="position: fixed;z-index: 1000;" v-model="showCutting" :emphasis="chaptersTree" emphasisType="2" :loadingall="loadingall" @hideimgup="showCutting=false" @submit="submitCuttingProblem"></cuttingProblem>
  </section>
</template>

<script>
import {
  $getQuesList,
  $getQuesChapter,
  $quesDetail,
  $addQues,
  $editQues,
  $delQues,
  $downloadTemplate,
  $importQues,
  $teaquestionAdds
} from "@api/resources";
import { mapState } from "vuex";
import { $ocradd} from "@api/resources";
import {
  changeEnChart,
  enChartToNum,
  formatFile,
  formatFileType,
} from "@utils";
import { PAGE_SIZE } from "@config";
import customPagination from "@comp/customPagination";
import cuttingProblem from "@comp/cuttingProblem";

export default {
  name: "resources_examinationQuestions",
  components: { customPagination,cuttingProblem },
  computed: {
    ...mapState("common", ["questionType"]),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断是否是组合题 */
    isCompose() {
      return function (val) {
        return val == 10;
      };
    },
    queNum() {
      return function (val) {
        let antecedent =
          (this.listPage.pageIndex - 1) * (this.listPage.size || PAGE_SIZE);
        let num = val + antecedent;
        num = (num > 9 && num) || `0${num}`;
        return num;
      };
    },
    enChart() {
      return function (val) {
        return changeEnChart(val);
      };
    },
  },
  data() {
    /* 题目标题验证 */
    const scqueTitleValidate = (rule, value, callback) => {
      if (
        !this.questionForm.scque_title &&
        !this.questionForm.scque_title_image
      ) {
        // 未上传文件
        callback("请填写题干或者上传题干图片");
      }
      callback();
    };
    return {
      qbId: null, // 题库id
      qbType: 1, // 题库类型
      listData: [], // 考试列表数据
      /* 列表分页参数 */
      listPage: {
        pageIndex: 1,
        total: 0,
      },
      /* 解析 */
      analysisDialog: false, // 展开解析弹窗
      analysisData: {}, // 题目解析数据
      /* 筛选 */
      dropdownVisible: false, // 题型筛选下拉展开
      searchForm: {
        type: {
          syque_typ_id: -1,
          syque_typ_name: "全部",
        },
      }, // 筛选表单
      chooseData: {}, // 选中的章节
      searchDialog: false, // 展开筛选章节弹窗
      chapterData: [], // 章
      chapterKey: "", // 筛选章（关键字）
      jointData: [], // 节
      jointKey: "", // 筛选节（关键字）
      /* 试题新增\编辑 */
      questionDialog: false, // 展开新增\编辑试题弹窗
      chaptersTree: [], // 章节数据(树)
      questionForm: {
        scque_ismakeup: 20,
        scque_title: "",
        option_arr: [{}],
      },
      questionRules: {
        scque_title: [{ validator: scqueTitleValidate, trigger: "change" }],
      },
      dataLoad: false,
      saveLoad: false,
      /* 导入 */
      importDialog: false,
      fileList: [], // 文件列表
      download1: false,
      download2: false,
      btnload: false,
      
      //批量加入
      allCheck: false,
      //部分选中
      someCheck: false,
      //是否显示整个页面
      showQuestionDoc: false,
      submitQuestionDocload:false,
      // 导入数量
      succNum:0,
      //导入的题库数据
      questionDoc:[],
      showCutting:false,//试卷识别弹窗
      loadingall:false,//等待弹窗
    };
  },
  created() {
    this.qbId = this.$route.params.id;
    this.qbType = this.$route.params.type;
    this.getData();
    this.getChaptersTree();
  },
  methods: {
    /** 获取题目数据 */
    async getData() {
      this.dataLoad = true;
      let { chapter, joint, query_word, type } = this.searchForm;
      let params = {
        query_word,
        sccou_id: this.qbId,
        pageindex: this.listPage.pageIndex,
      };
      if (type.syque_typ_id != -1) params.syque_typ_id = type.syque_typ_id;
      if (chapter) params.sccou_cha_id = chapter.id;
      if (joint) params.sccou_cha_id = joint.id;
      if (!chapter && !joint) delete params.sccou_cha_id;

      let { data: res } = await $getQuesList(this.qbType, params);
      this.dataLoad = false;
      this.listPage.total = res.allcount;
      this.listData = res.data;
      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 搜索数据 */
    searchData(clearChapter) {
      this.listData = [];
      this.listPage.pageIndex = 1;
      let { chapter, joint } = this.chooseData;
      this.searchForm = {
        ...this.searchForm,
        ...this.chooseData,
      };
      if (clearChapter) {
        if (!chapter) {
          delete this.searchForm.chapter;
          delete this.searchForm.joint;
        }
        if (!joint) {
          delete this.searchForm.joint;
        }
      }

      this.hideDialog("search");
      this.getData();
    },
    /** 获取章节数据(树) */
    async getChaptersTree() {
      let params = { sccou_id: this.qbId, is_trees: 1 };
      let { data } = await $getQuesChapter(params);
      data = data[0] || [];
      data.forEach((item) => {
        if (this.$isEmpty(item.children)) delete item.children;
        else {
          item.children.forEach((citem) => {
            if (this.$isEmpty(citem.children)) delete citem.children;
          });
        }
      });
      this.chaptersTree = data;
      this.$forceUpdate();
    },
    /**
     * 获取章节数据
     * @param {int} type 数据类型 1：章   2：节
     */
    async getChapters(type) {
      let { chapter } = this.chooseData;
      delete this.chooseData.joint;
      if (this.$isEmpty(chapter) && type == 2) return;
      let params = {
        chapter_word: (type == 1 && this.chapterKey) || this.jointKey,
        sccou_id: this.qbId,
      };
      /* 若选中章触发，传递章id获取节数据 */
      if (chapter && type != 1) params.sccou_cha_pid = chapter.id;
      let { data } = await $getQuesChapter(params);
      /* 章 */
      if (type == 1) {
        this.chapterData = data[0];
        /* 章数据更新，清除选中章及节数据 */
        delete this.chooseData.chapter;
        this.jointData = [];
        this.jointKey = "";
      } else {
      /* 节 */
        this.jointData = data[0];
      }
      this.$forceUpdate();
    },
    /**
     * 筛选节点选中
     * @param {string} el 筛选数据key
     * @param {number} id 筛选数据id
     * @param {string} name 筛选数据名称
     */
    chooseSearchKey(el, id, name) {
      this.chooseData[el] = { id, name };
      /* 根据选中数据类型，清空对应子级数据 */
      if (el == "chapter") {
        delete this.chooseData.joint;
        this.jointData = [];
        this.jointKey = "";
      }
      if (this.chooseData.chapter && el == "chapter") this.getChapters(2);
      this.$forceUpdate();
    },
    /** 切换题目类型 */
    chooseType(data) {
      this.searchForm = {
        ...this.searchForm,
        type: data,
      };
      this.searchData(false);
    },
    /** 新增试题 */
    addQuestion() {
      this.$refs.questionForm.validate(async (valid) => {
        if (valid) {
          this.saveLoad = true;
          let option_arr = this.$_.cloneDeep(this.questionForm.option_arr);
          let choiceQues = option_arr.filter((que) => {
            if (this.isChoice(que.syque_typ_id)) return que;
          });
          let answerCount = [];
          choiceQues.map((que) => {
            if (typeof que.answer == "number" || !this.$isEmpty(que.answer))
              answerCount.push(que.answer);
          });
          if (answerCount.length < choiceQues.length) {
            this.saveLoad = false;
            return this.$message.warning("请设定题目正确答案");
          }
          /* 遍历所有题目,设置章节id */
          option_arr.map((que) => {
            let { question_item } = que;
            /* 选择题,将正确答案赋值到选项 */
            if (this.isChoice(que.syque_typ_id)) {
              question_item.map((item) => (item.scque_ite_isanswer = 20));
              if (typeof que.answer == "number") {
                question_item[que.answer].scque_ite_isanswer = 10;
              } else {
                que.answer.map((a) => {
                  question_item[a].scque_ite_isanswer = 10;
                });
              }
            }
            if (que.chapter && !this.$isEmpty(que.chapter)) {
              que.sccou_cha_id = que.chapter[que.chapter.length - 1];
            }
            question_item.map((opt, opt_index) => {
              opt.scque_ite_code = changeEnChart(opt_index);
            });
            delete que.answer;
            delete que.chapter;
            que = { ...que, question_item: [...question_item] };
          });
          let params = {
            ...this.questionForm,
            sccou_id: this.qbId,
            option_arr: [...option_arr],
          };
          if (params.scque_id) return this.editQuestion(params); // 题目id存在，执行数据编辑保存
          let res = await $addQues(params);
          this.saveLoad = false;
          if (res) {
            this.hideDialog("question"); // 关闭表单弹窗
            this.getData();
            this.$message.success("试题添加成功");
          }
        }
      });
    },
    /**
     * 获取题目详情
     * @param {number} id 题目id
     */
    async getQuestionDetail(id) {
      let { data } = await $quesDetail(id);
      let option_arr = [];
      console.log(data)
      data.option_arr.forEach((que) => {
        let que_item = {
          answer: [],
          syque_typ_id: que.syque_typ_id,
          scque_difficulty: que.scque_difficulty,
          question_item: [...que.question_item],
        };
        // if (que.sccou_cha_title)
        //   que_item.chapter = que.sccou_cha_title.split(',').map(Number);
        if (que.sccou_cha_pid && que.sccou_cha_id)
        que_item.chapter = [que.sccou_cha_pid, que.sccou_cha_id];
        if (data.scque_ismakeup == 10) {
          if (que.scque_title) que_item.scque_children_title = que.scque_title;
          if (que.scque_title_image)
            que_item.scque_children_image = que.scque_title_image;
        }
        if (que.scque_analysis) que_item.scque_analysis = que.scque_analysis;
        que.sure_answer.map((item) => {
          que_item.answer.push(enChartToNum(item));
        });
        if(que_item.syque_typ_id!=2){
            que_item.answer = que_item.answer.length > 1 && que_item.answer || que_item.answer[0];
        }
        option_arr.push(que_item);
      });
      let formData = {
        scque_id: data.scque_id,
        scque_title: data.scque_title,
        scque_ismakeup: data.scque_ismakeup,
        option_arr: [...option_arr],
      };
      if (data.scque_title_image)
        formData.scque_title_image = data.scque_title_image;
      this.questionForm = { ...formData };
      this.showDialog("question");
    },
    /**
     * 编辑题目
     * @param {object} params 表单数据
     */
    async editQuestion(params) {
      let res = await $editQues(params);
      this.saveLoad = false;
      if (res) {
        this.hideDialog("question"); // 关闭表单弹窗
        this.getData();
        this.$message.success("试题编辑保存成功");
      }
    },
    /**
     * 删除题目
     * @param {number} id 题目id
     */
    delQuestion(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除当前试题？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await $delQues(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "试题已删除！",
            });
            this.listPage.total =
              (this.listPage.total - 1 < 0 && 0) || this.listPage.total - 1;
            if (this.listData.length == 1 && this.listPage.pageIndex != 1) {
              this.listPage.pageIndex = this.listPage.pageIndex - 1;
            }
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 重置选中筛选节点 */
    resetSearchChoose() {
      this.chapterKey = "";
      this.jointData = [];
      this.jointKey = "";
      this.chooseData = {};
    },
    /**
     * 展开弹窗
     * @param {string} el 弹窗元素
     * @param {object} data 解析数据
     */
    async showDialog(el, data) {
      if (data) this.analysisData = { ...data };
      this[`${el}Dialog`] = true;
      if (el == "search") {
        let { chapter, joint } = this.searchForm;
        await this.getChapters(1);
        if (chapter && chapter.id) {
          this.chooseSearchKey("chapter", chapter.id, chapter.name);
        }
        if (joint && joint.id) {
          this.chooseSearchKey("joint", joint.id, joint.name);
        }
      }
      this.$nextTick(() => {
        if(this.$refs.questionForm)
        this.$refs.questionForm.clearValidate();
      });
      this.$forceUpdate();
    },
    /**
     * 关闭弹窗
     * @param {string} el 弹窗元素
     */
    hideDialog(el) {
      this[`${el}Dialog`] = false;
      if (el == "search") {
        this.resetSearchChoose();
      }
      if (el == "question") {
        this.questionForm = { scque_ismakeup: 20, option_arr: [{}] };
        this.$refs.questionForm.clearValidate();
      }
    },
    /**
     * 图片上传成功
     * @param {object} data 响应数据
     * @param {string} el 图片对应字段变量
     */
    uploadImgSuccess({ data, msg }, el) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      el = el.split(".");
      if (el.length == 1) this[el[0]] = data.address;
      if (el.length == 2) this[el[0]][el[1]] = data.address;
      if (el.length == 3) this[el[0]][el[1]][el[2]] = data.address;
      if (el.length == 4) this[el[0]][el[1]][el[2]][el[3]] = data.address;
      if (el.length == 5)
        this[el[0]][el[1]][el[2]][el[3]][el[4]] = data.address;
      if (el.length == 6)
        this[el[0]][el[1]][el[2]][el[3]][el[4]][el[5]] = data.address;
      this.$forceUpdate();
    },
    /** 选中题目是否为组合题 */
    checkIsCompose(val) {
      let { scque_title, scque_title_image } = this.questionForm;
      let form = { scque_ismakeup: val, option_arr: [{}], scque_title };
      if (scque_title_image) form.scque_title_image = scque_title_image;
      this.questionForm = { ...form };
      this.$refs.questionForm.clearValidate();
      this.$forceUpdate();
    },
    /**
     * 选中题目题型
     * @param {number} val 题型选中值
     * @param {number} que_index 题目数组下标
     */
    chooseQuesType(val, que_index) {
      let option = {
        scque_ite_title: "",
        scque_ite_isanswer: 20,
        scque_ite_image: "",
      };
      let question_item = [];
      let { option_arr } = this.questionForm;
      if (this.isChoice(val)) {
        let loopTime = (val == 5 && 2) || 4;
        for (let i = 0; i < loopTime; i++) {
          question_item.push({ ...option });
        }
      } else {
        question_item = [
          {
            scque_ite_title: "",
            scque_ite_isanswer: 10,
            scque_ite_image: "",
          },
        ];
      }
      option_arr[que_index] = {
        ...option_arr[que_index],
        answer: (val == 2 && []) || "",
        question_item: [...question_item],
      };
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /** 添加组合题小题 */
    appendQues() {
      let { option_arr } = this.questionForm;
      option_arr.push({});
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 移除组合题小题
     * @param {number} que_index 题目数组下标
     */
    removeQues(que_index) {
      let { option_arr } = this.questionForm;
      this.$_.pullAt(option_arr, que_index);
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /**
     * 添加题目选项
     * @param {number} que_index 题目数组下标
     */
    appendQueOption(que_index) {
      let { option_arr } = this.questionForm;
      let { question_item } = option_arr[que_index];
      const option = {
        scque_ite_title: "",
        scque_ite_isanswer: 20,
        scque_ite_image: "",
      };
      question_item.push(option);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
      };
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
    },
    /**
     * 移除题目选项
     * @param {number} que_index 题目数组下标
     * @param {number} opt_index 题目选项数组下标
     */
    removeQueOption(que_index, opt_index) {
      let { option_arr } = this.questionForm;
      let { question_item, answer } = option_arr[que_index];
      if (answer + "") {
        if (typeof answer == "number") {
          if (answer == opt_index) answer = "";
          if (answer > opt_index) answer = (answer - 1 < 0 && "") || answer - 1;
        } else {
          if (answer.indexOf(opt_index) != -1) {
            let a_index = answer.indexOf(opt_index);
            this.$_.pullAt(answer, a_index);
          }
          let newAnswer = [];
          answer.map((item) => {
            if (item > opt_index) newAnswer.push(item - 1);
            else newAnswer.push(item);
          });
          answer = [...newAnswer];
        }
      }
      this.$_.pullAt(question_item, opt_index);
      option_arr[que_index] = {
        ...option_arr[que_index],
        question_item: [...question_item],
        answer,
      };
      this.questionForm = { ...this.questionForm, option_arr: [...option_arr] };
      this.$forceUpdate();
    },
    /** 下载题目模板 type 1=>excel 2=>word */
    async downloadTemplate(type) {
      if(type==1){
        this.download1= true;
      }else{
        this.download2= true;
      }
      let { data } = await $downloadTemplate({ type: type });
      if(type==1){
        this.download1= false;
      }else{
        this.download2= false;
      }
      this.$fileDownload(data.full_file_url);
    },
    /** 导入题目 */
    async uploadQues() {
      if (this.$isEmpty(this.fileList))
      return this.$message.warning("请上传需要导入的文件");
      this.btnload = true;
      let params = {
        plque_ban_id: this.qbId,
        inportfile: this.fileList[0].url,
      };
      this.fileList = [];
      let res = await $importQues(params);
      this.btnload = false;
      if (res) {
        let { data } = res;
        this.hideDialog("import");
        if(data.isword){
            if (data.succNum > 0) {
                this.questionDoc = data.questions
                this.succNum = data.succNum
                this.showQuestionDoc = true
            }
        }else{
            if (data.errorNum) {
                this.$message.warning(`共导入${data.allCount}道题，导入失败${data.errorNum}道`);
            } else {
                this.$message.success(`成功导入${data.allCount}道题`)
            }
            this.getData();
            if (data.errorNum && data.errorFileUrl) this.$fileDownload(data.errorFileUrl)
        }


        // if (data.errorNum) {
        //   this.$message.warning(
        //     `共导入${data.allCount}道题，导入失败${data.errorNum}道`
        //   );
        //   if (data.errorFileUrl) this.$fileDownload(data.errorFileUrl);
        // } else {
        //   this.$message.success(`成功导入${data.allCount}道题`);
        // }
        
      }
    },
    /**
     * 文件上传成功
     * @param {object} data 响应结果
     * @param {string} name 上传原文件名
     */
    uploadSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      let file = { name, type: data.file_type, url: data.address };
      this.fileList = [file];
    },
    /** 删除上传文件 */
    deleteUploadFile(index) {
      this.fileList.splice(index, 1);
    },
    
    //添加小题答案
    addDocQuestion(item,index){
        if(item.question_item.length<20){
            let letter=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T"]
            item.question_item.push({scque_ite_code:letter[item.question_item.length],scque_ite_isanswer:20,scque_ite_title:""})
            this.questionDoc[index]=item
        }else{
            this.$message({
                type: "info",
                duration: 1500,
                message: "答案上限",
            });
        }
    },
    //删除答案
    deleteDocQuestionItem(item,index2){
        if(item.question_item.length>1){
            let letter=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T"]
            if(item.syque_typ_id==1||item.syque_typ_id==5){
                if(item.answer==item.question_item[index2].scque_ite_code){
                    item.answer=""
                }
            }
            item.question_item.splice(index2,1)
            item.question_item.map((item2,index)=>{
                item2.scque_ite_code=letter[index]
            })
        }else{
            this.$message({
                type: "info",
                duration: 1500,
                message: "至少保留一个答案",
            });
        }
    },
    //删除大题
    deleteQuestionDocItem(index){
        if( this.questionDoc.length>1){
            this.questionDoc.splice(index,1)
        }else{
            this.$message({
                type: "info",
                duration: 1500,
                message: "至少保留一个大题",
            });
        }
    },
    // 提交表单
    async questionDocsubmit(){
        let pass=true
        let error=""
        let newsDoc=JSON.parse(JSON.stringify(this.questionDoc))
        newsDoc.map((item,index)=>{
          if(item.sccou_cha_id){
              item.sccou_cha_id=item.sccou_cha_id[item.sccou_cha_id.length - 1]
          }
          if(pass && item.scque_title.length<1){
              error="请填写第"+(index+1)+"题题目"
              pass=false
          }
          if(item.syque_typ_id==1){
              //遍历选择题里面的小题
              item.question_item.map(item2 => {
                  if(item.answer==item2.scque_ite_code){
                      item2.scque_ite_isanswer=10
                  }else{
                      item2.scque_ite_isanswer=20
                  }
              })
              let ispass = item.answer.length>0
              let isvalpass = item.question_item.some(function(item2){
                  return item2.scque_ite_title==""
              })
              if(pass&&!ispass){
                  error="请勾选第"+(index+1)+"题正确答案"
                  pass=false
              }
              if(pass&&isvalpass){
                  error="请填写第"+(index+1)+"题正确答案"
                  pass=false
              }
          }
          if(item.syque_typ_id==5||item.syque_typ_id==2){
              let ispass = item.question_item.some(function(item2){
                  return item2.scque_ite_isanswer ==10
              })
              let isvalpass = item.question_item.some(function(item2){
                  
                  return item2.scque_ite_title==""
              })
              if(pass&&!ispass){
                  error="请勾选第"+(index+1)+"题正确答案"
                  pass=false
              }
              if(pass&&isvalpass){
                  error="请填写第"+(index+1)+"题正确答案"
                  pass=false
              }
          }
          if(item.syque_typ_id==3||item.syque_typ_id==4||item.syque_typ_id==7||item.syque_typ_id==8){
              let scque_ite_title=[]
              item.question_item.map((item2,index2)=>{
                  //填空题设置默认值
                  if(item2.scque_ite_title.length>0){
                      scque_ite_title.push(item2.scque_ite_title)
                  }else{
                      error="请填写第"+(index+1)+"题正确答案"
                      pass=false
                  }
              })
              item.question_item=[{scque_ite_code:"",scque_ite_isanswer:"10",scque_ite_title:scque_ite_title.toString()}] 
          }
      })
      if(!pass){
          this.$message({
              type: "info",
              duration: 2500,
              message: error
          });
      }else{
          this.submitQuestionDocload=true
          let params = {
              sccou_id: this.qbId,
              plque_ban_type: this.qbType,
              questions: newsDoc
          };
          let res = await $teaquestionAdds(params);
          if(res){
              this.$message({
                  type: "success",
                  duration: 1500,
                  message: "试题已加入！",
              });
              this.showQuestionDoc=false
              this.getData();
          }
          this.submitQuestionDocload=false
      }
    },
    // 试卷切题完成请求
    async submitCuttingProblem(newsDoc){
      let params = {
        tetea_id: this.qbId,
        qus_type:4,
        questions: newsDoc
      };
      this.loadingall=true
      let res = await $ocradd(params);
      if(res){
        this.showCutting=false;
        this.loadingall=false;
        this.getData();
        this.$message.success("试题添加成功");
      }else{
        this.loadingall=false;
      }
    },
  },
};
</script>